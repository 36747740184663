import React from "react";
import { Trash2 } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { Edit2 } from "react-feather";

const Usuarios = () => {
  const columns = [
    {
      name: "Title",
      width: "70%",
      selector: (row) => row.title,
    },
    {
      name: "Year",
      width: "15%",
      selector: (row) => row.year,
    },
      {
      name: "Actions",
      width: "15%",
      cell: (row) => {
        return (
          <>
          <Edit2></Edit2>
          &nbsp;&nbsp;
          <Trash2 size="25"></Trash2>
          </>
        )
      }
    },
  ];

  const data = [
    {
      id: 1,
      title: "Homem Aranha",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
    {
      id: 1,
      title: "Homem Aranha",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
    {
      id: 1,
      title: "Homem Aranha",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
    {
      id: 1,
      title: "Homem Aranha",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
    {
      id: 1,
      title: "Homem Aranha",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
    {
      id: 1,
      title: "Homem Aranha",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];
  return (
    <>
      <h1>USUARIOS</h1>
      <DataTable
        columns={columns}
        data={data}
        pagination
        highlightOnHover
        responsive
        striped
      />
    </>
  );
};

export default Usuarios;
