import api from "./api";

export const CadastraEmail = async (email) => {
  console.log('email', email)
  return api
    .get(`/public/newsletter/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

