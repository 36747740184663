import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FaInstagram, FaFacebookF, FaLinkedinIn, FaTwitter, } from "react-icons/fa";
import LogoInfoWay from "../../assets/images/InfoWayLogoTransp.png";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col,
  Label, Input, Button,} from "reactstrap";
import './footerStyle.css';  

const Footer = () => {
    const history = useNavigate();
    const [modalLogin, setModalLogin] = useState(false);
    const [isLoged, setIsLogged] = useState(localStorage.getItem("IsLoged"));

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const submitLogin = () => {
      history("/Admin");
    };
    const logout = () => {
      localStorage.removeItem("IsLoged");
      setIsLogged(false);
      history("/");
    };

    const btnSocial = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      color: "white",
      height: "45px",
      width: "45px",
      fontSize: "2.8rem",
      backgroundImage: "linear-gradient(rgb(23, 144, 243), rgb(7, 19, 184))",
      border: "none",
      borderRadius: "50%",
      transition: "0.3s",
    };

    const footerStyle = {
      position: "relative",
      display: "flex",
      justifyContent:"center",
      alignItems: "center",
      marginTop: "25px",
      padding: "1rem 0rem",
      width: "100%",
      backgroundImage: "linear-gradient(rgb(1, 18, 82), rgb(27, 27, 27))",
    };

    return (
      <>
        <Modal className="modal-dialog-centered" 
               isOpen={ modalLogin }>      
          <ModalHeader>Login Admin</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Label>E-mail</Label>
                <Input type="email"
                       name="email"
                       id="email"
                       placeholder="Insira seu e-mail"
                       value={ email }
                       onChange={(e) => setEmail(e.target.value)}>
                </Input>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <Label>Senha</Label>
                <Input type="password"
                       name="password"
                       id="passsword"
                       placeholder="Insira sua senha"
                       value={ password }
                       onChange={(e) => setPassword(e.target.value)}>
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => submitLogin()}>Login</Button>{" "}
            <Button onClick={() => setModalLogin(false)}>Cancelar</Button>
          </ModalFooter>
        </Modal>

        <footer style={ footerStyle }>
          <div
            className="footer-responsive"
            style={{
            position: "relative",
            display: "flex",
            justifyContent:"space-between",
            alignItems: "center",
            width: "1200px",
            padding: "0rem 0rem",
            }}>
              <span 
                style={{
                  position: "fixed",
                  right: "0",
                  bottom: "0",
                  cursor: "wait",
                  color: "white",
                  margin: "1rem",
                }}
                onClick={() => setModalLogin(true)}>
                &#960;
              </span> 
            <div
              className="nav-responsive" 
              style={{
                display: "flex",
                fontSize: "1rem", 
                alignItems: "center",
              }}>
              <span>
                Copyright &copy;
                <img
                  src={ LogoInfoWay }
                  alt="InfoWay"
                  style={{
                    width: "100px",
                    height: "auto",
                    margin: "0 0.5rem",
                  }}
                  width="100px"
                  height="auto"/>
              </span>
              <span>
                {
                  new Date().getFullYear()
                } - Todos os direitos reservados
              </span>
            </div>

            <div className="nav-social">
              <a style={ btnSocial }
                 className="btn btn-dark btn-social mx-2"
                 href="https://www.instagram.com/roboenitron/"
                 target="_blank" rel="noreferrer">
                  <FaInstagram title="Instagram" size={30}/>
              </a>
              {/*<a style={ btnSocial }
                 className="btn btn-dark btn-social mx-2"
                 href="https://www.facebook.com/Enitron-109398244797868"
                 target="_blank" rel="noreferrer">
                  <FaFacebookF title="Facebook" size={25}/>
              </a>*/}
              <a style={ btnSocial }
                 className="btn btn-dark btn-social mx-2"
                 href="https://www.linkedin.com/in/enitron-robótica-214ba521a/"
                 target="_blank" rel="noreferrer">
                  <FaLinkedinIn title="Linkedin" size={25}/>
              </a>
              {/*<a style={ btnSocial }
                 className="btn btn-dark btn-social mx-2"
                 href="https://twitter.com/Enitron_Real"
                 target="_blank" rel="noreferrer">
                  <FaTwitter title="Twitter" size={25}/>
              </a>*/}
            </div>

          </div>
        </footer>
      </>
    );
  };
  
  export default Footer;