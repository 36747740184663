/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { S3 } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditorImage from "./editorImage.js";
import "react-toastify/dist/ReactToastify.min.css";
import imgAvatar from "../../assets/images/avatar.png";
import { CadastroUsuario } from "../../services/usuarios";
import AvatarEditor from "react-avatar-editor";
import api from "../../services/api";
import { Paises, Estados } from "../../components/locais";
import {
  Modal,
  Progress,
  Spinner,
  ModalBody,
  Button,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

/* Constantes para que haja a coleta de dados do cadastro! */

const Cadastro = () => {
  const editor = useRef(null);
  const [turnPhoto, setTurnPhoto] = useState(0);
  const [scalePhoto, setScalePhoto] = useState(1);
  const [fotoCrop, setFotocrop] = useState("");

  const onCrop = () => {
    if (editor !== null) {
      const url = editor.current.getImageScaledToCanvas().toDataURL();
      //setuserProfilePic(url);
      saveImage(url);
      setEditando(!editando);
    }
  };

  const [avatar, setAvatar] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [github, setGithub] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("AC");
  const [pais, setPais] = useState("BR");
  const [especialidade, setEspecialidade] = useState("");
  const [descricao, setDescricao] = useState("");
  const [email, setEmail] = useState("");
  const [reEmail, setReEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [spinner, setSpinner] = useState("");
  const [porcentageUp, setPorcentageUp] = useState(0);
  const [editando, setEditando] = useState(false);

  const history = useNavigate();

  const submitCadastro = () => {
    //Condicionais para espaço em branco

    //Nome
    if (nome === "" || telefone === "" || cidade === "" || especialidade === "" || descricao === "" || email === "" || reEmail === "" || password === "" || confirmpassword === "" || avatar === "") {

    if (nome === "" || null) {
      toast.error("Digite seu nome completo!");
    }

    //Telefone
    if (telefone === "" || null) {
      toast.error("Digite o seu número de telefone!");
    }

    //Cidade
    if (cidade === "" || null) {
      toast.error("Digite a sua cidade!");
    }

    //Especialidade
    if (especialidade === "" || null) {
      toast.error("Me mostre sua especialidade! Não se garante em nada ?");
    }

    //Descrição
    if (descricao === "" || null) {
      toast.error("Me fale um pouco sobre você!");
    }

    //E-mail
    if (email === "" || null) {
      toast.error("Digite o seu endereço de e-mail!");
    }

    //Confirmar E-mail
    if (reEmail === "" || null) {
      toast.error("Confirme o seu endereço de e-mail!");
    }

    //Senha
    if (password === "" || null) {
      toast.error("Digite a sua senha!");
    }

    //Confirmar Senha
    if (password === "" || null) {
      toast.error("Confirmar a sua senha!");
    }
  
    // Condicionais de confirmação email e senha
    if (email !== reEmail) {
      toast.error("Os E-mails não coincidem.");
    }

    if (password !== confirmpassword) {
      toast.error("As senhas não coincidem.");
    }

    if (avatar === "") {
      toast.error("Escolha uma foto para o seu perfil.");
    }

  } else {
    uploadAvatar(fotoCrop);
  }
  };

  const saveImage = (url) => {
    setAvatar(url);
  };

  const uploadAvatar = (idMedia) => {
    setSpinner(true);
      const extension = idMedia.name.split(".").pop();
      let nameFile = `${nome.replaceAll(" ", "")}.${extension}`;
      const target = {
        Bucket: "avatarusuarios",
        uploadId: "qweqwe",
        Key: nameFile,
        Body: idMedia,
        ACL: "public-read"
      };
      try {
        const parallelUploads = new Upload({
          client: new S3({
            region: "sa-east-1",
            credentials: {
              accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
              secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            },
          }),
          leavePartsOnError: false,
          params: target,
        });
        parallelUploads.on("httpUploadProgress", (progress) => {
          const porcentage = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPorcentageUp(porcentage);
          
        });
        parallelUploads
          .done()
          .then((dados) => {
            
            const body = {
              nome,
              telefone,
              linkedin,
              github,
              cidade,
              estado,
              pais,
              especialidade,
              descricao,
              email,
              reEmail,
              password,
              confirmpassword,
              type: "user",
              forumpoints: 0,
              avatar: `https://avatarusuarios.s3.sa-east-1.amazonaws.com/${nameFile}`,
            };

            CadastroUsuario(body)
              .then((response) => {
                console.log("RESPOSTA DA API SUSSES: ", response);
                
                if (response.status === 200 || response.status === 201) {
                  toast.success("Usuário criado com sucesso!");
                  setSpinner(false);
                  history("/");
                } else {
                  
                  toast.error(response.data.msg);                  
                }
              })
              .catch((err) => {
                console.log("RESPOSTA DA API ERRO: ", err.response);
                
                  toast.error(err.response.data.msg);
                  setSpinner(false);
              });
            
          })
          .catch((err) => {
            console.log(err);
            //toast.error("Erro ao Realizar o Upload da Foto!");
          });
      } catch (error) {
        console.log(error);
      }    
  };

  /* Após declarada as funções, renderizar no site  */
  return (
    <>
      <Modal isOpen={spinner} className="modal-dialog-centered">
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Row>
              <Col sm="12">
                <h5>Salvando cadastro, aguarde ... </h5>
                <Progress
                  striped
                  className="progress-bar-primary"
                  value={porcentageUp}
                >
                  {" "}
                  {porcentageUp} %{" "}
                </Progress>
              </Col>
              
            </Row>
          </div>
        </ModalBody>
      </Modal>
      <br></br>
      <Card className="cardpadrao">
        <CardHeader>
          <h3> Cadastro do Usuário </h3>
        </CardHeader>
        <CardBody>
          {/* Imagem */}
          <Row>
            <Col sm="6">
            <div
              style={{
                display: editando === false ? "flex" : "none",
                justifyItems: "right",
                alignItems: "right",
                justifyContent: "right"
              }}
            >
              <img src={avatar === "" ? imgAvatar : avatar} alt="avatar" width={200} height={200} 
              onClick= {() => setEditando(!editando) } />
            </div>
            
            <div
              style={{
                display: editando === false ? "none" : "flex",
                justifyItems: "right",
                alignItems: "right",
                justifyContent: "right"
                
              }}
            >
              <AvatarEditor
                ref={editor}
                image={fotoCrop}
                width={250}
                height={250}
                border={20}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scalePhoto}
                rotate={turnPhoto}
              />
              <EditorImage
                setScalePhoto={setScalePhoto}
                setFotocrop={setFotocrop}
                setTurnPhoto={setTurnPhoto}
                turnPhoto={turnPhoto}
                onCrop={onCrop}
              />
            </div>
            </Col>
            <Col sm="6">
              <div  style={{
                display: editando === false ? "flex" : "none",
                justifyItems: "left",
                alignItems: "left",
                justifyContent: "left",
                flexDirection: "column",
                fontSize: "12px",
                
              }}>
                <br></br>
                <br></br>
                <h6>Escolha uma foto para o seu perfil:</h6>
                1º - Clique na foto para editar<br /> 
                2º - Clique no botão "Escolher arquivo"<br /> 
                3º - Escolha a foto desejada<br /> 
                4º - Pode editar a foto como desejar<br /> 
                5º - Clique no botão "Salvar"<br /> 
                </div>

              </Col>
          </Row>

          <br></br>
          <Row>
            <Col sm="12" lg="6">
              <Label>Nome Completo:</Label>
              <Input
                value={nome}
                type="text"
                name="nome"
                placeholder="Exemplo: Robô Enitron"
                onChange={(e) => setNome(e.target.value)}
              />
            </Col>

            <Col sm="12" lg="6">
              <Label>Telefone:</Label>
              <Input
                value={telefone}
                type="text"
                name="telefone"
                placeholder="Exemplo: (11)99999-9999"
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Col>
          </Row>
          <br></br>

          <Row>
            <Col sm="12" lg="6">
              <Label>Linked In:</Label>
              <Input
                value={linkedin}
                type="text"
                name="linkedin"
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </Col>

            <Col sm="12" lg="6">
              <Label>Github:</Label>
              <Input
                value={github}
                type="text"
                name="github"
                onChange={(e) => setGithub(e.target.value)}
              />
            </Col>
          </Row>
          <br></br>

          <Row>
            <Col sm="12" lg="6">
              <Label>Cidade</Label>
              <Input
                value={cidade}
                type="text"
                name="cidade"
                onChange={(e) => setCidade(e.target.value)}
              />
            </Col>

            <Col sm="12" lg="6">
              <Label>Estado</Label>
              <Input
                value={estado}
                type="select"
                name="estadi"
                onChange={(e) => setEstado(e.target.value)}
              >
                <Estados></Estados>
              </Input>
            </Col>
          </Row>
          <br></br>

          <Row>
            <Col sm="12" lg="6">
              <Label>País:</Label>
              <Input
                value={pais}
                type="select"
                name="pais"
                onChange={(e) => setPais(e.target.value)}
              >
                {" "}
                <Paises></Paises>
              </Input>
            </Col>

            <Col sm="12" lg="6">
              <Label>Especialidade</Label>
              <Input
                value={especialidade}
                type="text"
                name="especialidade"
                placeholder="Digite aqui no que você se garante! "
                onChange={(e) => setEspecialidade(e.target.value)}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col sm="12" lg="6">
              <Label>E-mail:</Label>
              <Input
                value={email}
                type="email"
                name="email"
                placeholder="Exemplo: roboenitron@enitron.com"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>

            <Col sm="12" lg="6">
              <Label>Confirma E-mail:</Label>
              <Input
                value={reEmail}
                type="remail"
                name="remail"
                placeholder="Exemplo: roboenitron@enitron.com"
                required
                onChange={(e) => setReEmail(e.target.value)}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col sm="12" lg="6">
              <Label>Senha:</Label>
              <Input
                value={password}
                type="password"
                name="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>

            <Col sm="12" lg="6">
              <Label> Confirmar Senha:</Label>
              <Input
                value={confirmpassword}
                type="password"
                name="password"
                required
                onChange={(e) => setConfirmpassword(e.target.value)}
              />
            </Col>
          </Row>
          <br></br>

          <Row>
            <Col sm="12">
              <Label>Descrição</Label>
              <Input
                value={descricao}
                type="text"
                name="descricao"
                placeholder="Digite uma breve descrição sua! "
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Col>
          </Row>

          <br></br>

          <Button onClick={() => submitCadastro()} block>
            {" "}
            Realizar Cadastro{" "}
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

export default Cadastro;
