/* eslint-disable no-unused-vars */
import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";

import { Button, Input, ButtonGroup, UncontrolledTooltip } from "reactstrap";
import {
  Download,
  RotateCcw,
  RotateCw,
  Image,
  Save,
  Trash2,
  X,
} from "react-feather";
import { toast } from "react-toastify";

const EditorImage = ({
  setFotocrop,
  setScalePhoto,
  setTurnPhoto,
  turnPhoto,
  onCrop,
  dbImage,
  removeImages,
}) => {
  const [modalFoto, setModalFoto] = useState(false);
  const [expandedImage, setExpandedImage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const url_string = window.location.href;
  const url = new URL(url_string);
  const data = url.searchParams.get("ncl");

  return (
    <>
      <div className="image-upload">
        <Input
          // style={{ width: "220px", marginTop: "5px" }}
          type="range"
          min="1"
          max="5"
          step="0.1"
          onChange={(e) => setScalePhoto(e.target.value)}
        ></Input>
        <Input
          type="file"
          name="inputhidden"
          style={{ display: "none" }}
          accept="image/png, image/jpeg, .stl"
          id="inputhidden"
          onChange={(e) => setFotocrop(e.target.files[0])}
        ></Input>
        <div
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}>
        <ButtonGroup
          size="sm"
          style={{ width: "250px", marginTop: "10px"}}
          className="mb-1"
        >
          <Button id="bt1" color="primary">
            <label for="inputhidden">
              <div
                style={{
                  display: "flex",
                  width: "55px",
                  height: "30px",
                  margin: "-20px",
                  marginTop: "-5px",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <Image size={14} color="#fff" />
              </div>
            </label>
          </Button>
          <Button
            id="bt2"
            onClick={() => setTurnPhoto(turnPhoto - 90)}
            color="primary"
          >
            <RotateCcw size={14} />
          </Button>
          <Button
            id="bt3"
            onClick={() => setTurnPhoto(turnPhoto + 90)}
            color="primary"
          >
            <RotateCw size={14} />
          </Button>
          <Button id="bt4" onClick={() => onCrop()} color="primary">
            <Save size={14} />
          </Button>
        </ButtonGroup>
        <UncontrolledTooltip placement="top" target="bt1">
          Adicionar / Alterar
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="top" target="bt2">
          Girar esquerda
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="top" target="bt3">
          Girar direita
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="top" target="bt4">
          Salvar mudança
        </UncontrolledTooltip>
        </div>
      </div>
    </>
  );
};

export default EditorImage;
