import React from "react";

import SidebarAdmin from "../../components/SidebarAdmin/index.js";
import ContentAdmin from "../../components/contentAdmin/index.js";
import { Outlet } from "react-router-dom";

import { Users } from "react-feather";
import { Card, Row, Col, CardBody } from "reactstrap";

const admin = () => {
  return (
    <>
    <br></br>
    <br></br>
    <br></br>

      <Row>
        <Col sm="3">
          <SidebarAdmin />
        </Col>

        <Col sm="9">
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default admin;
