/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
import coordinator from "../../../src/assets/images/bill.jpeg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsEye, BsStars } from "react-icons/bs";
import { FaMountain } from "react-icons/fa";
import { BsFillTrophyFill } from "react-icons/bs";
import CardEquipe from "../../components/CardEquipe";
import CardRobo from "../../components/CardRobo";
import SlideCarouselHome from "../../components/SlideCarouselHome";
import SlideTrack from "../../components/SlideTrack";
import backgroundTech from "../../../src/assets/images/background-tech-compress.jpg";
import backgroundSobre from "../../../src/assets/images/background1.png";

import EnitronMaster2 from "../../../src/assets/images/Enitron_Master2.jpg";
import EnitronDance1 from "../../../src/assets/images/Enitron_Dance1.jpg";
import EnitronDance2 from "../../../src/assets/images/Enitron_Dance2.jpg";
import EnitronRoboFutebol1 from "../../../src/assets/images/Enitron_Robo_Futebol1.jpg";
import EnitronRoboFutebol2 from "../../../src/assets/images/Enitron_Robo_Futebol2.jpg";
import EnitronMicromouse2 from "../../../src/assets/images/Enitron_Micromouse2.jpg";

import armCyborg from "../../../src/assets/images/arm-compress.png";
import { toast } from "react-toastify";
import { CadastraEmail } from "../../services/newsletter";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";

import CarouselBasic from "./carrousel";

const Home = () => {
  const [modalLogin, setModalLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [emailNews, setEmailNews] = useState("");
  console.log("email", email);
  const [password, setPassword] = useState("");
  const [isLoged, setIsLogged] = useState(localStorage.getItem("IsLoged"));
  const [openSobreNos, setOpenSobreNos] = useState(false);
  const localGaleria = "https://imagenssite.s3.amazonaws.com/galeria/";

  const logado = () => {
    history("/Dashboard");
  };

  const GaleriaEquipe = () => {
    const [modalGaleria, setModalGaleria] = useState(false);
    const [imageGaleria, setImageGaleria] = useState("");
    const quant = 18;

    const toggle = () => setModalGaleria(!modalGaleria);
    return (
      <>
        <Modal 
          className="modal-dialog-centered"
          isOpen={modalGaleria}
          centered="true"
          size="lg"
          toggle={toggle}>
            <Button style={{width: "50px"}} color="danger" onClick={toggle}>
              X
            </Button>
            <ModalBody>
              <div style={{width: "100%", height: "400px", overflow: "hidden"}}>
                <img style={{display: "block", width: "100%", height: "100%",objectFit: "cover"}} src={imageGaleria}/>
              </div>
            </ModalBody>
        </Modal>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">
                Galeria de Fotos
              </h2>
              <h3 className="section-subheading text-muted">Equipe Enitron</h3>
            </div>
          </div>
          <div className="container">
            {Array.from({ length: 3 }).map((_, rowIndex) => (
              <div className="row" key={rowIndex}>
                {Array.from({ length: 6 }).map((_, colIndex) => {
                  const imageIndex = rowIndex * 6 + colIndex;
                  return (
                    <div
                      className="col-md-2 col-sm-4 portfolio-item"
                      style={{
                        backgroundImage: `url(${localGaleria}${
                          imageIndex + 1
                        }.jpeg)`,
                        backgroundSize: "cover",
                        height: "200px",
                        cursor: "pointer"
                      }}
                      onClick={() => {setModalGaleria(true); setImageGaleria(`${localGaleria}${imageIndex + 1}.jpeg`)}}
                      key={imageIndex}
                    ></div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const history = useNavigate();

  const submitLogin = () => {
    // localStorage.setItem("IsLoged", true);
    // setIsLogged(true);

    // const body = {
    //   email: email,
    //   password: password,
    // };

    // api
    //   .post("auth/login/", body)
    //   .then((r) => {
    //     console.log("RESULTADO API: ", r);
    //     localStorage.setItem("token", r.data.token);
    //     setModalLogin(false);
    //     toast.success("Login realizado com sucesso!");
    //     setIsLogged(true);
    //     logado();
    //   })
    //   .catch((err) => toast.error(err.response.data.msg));
    history("/Admin");
  };

  const irPara = () => {
    history("/Cadastro");
    setModalLogin(false);
  };

  const logout = () => {
    localStorage.removeItem("IsLoged");
    setIsLogged(false);
    history("/");
  };

  const [offSetY, setOffSetY] = useState(0);
  const handleScroll = () => setOffSetY(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const dadosRobo = [
    {
      titulo: "Enitron",
      descricao:
        "ENITRON é o nome dado a todo protótipo de robô desenvolvido pela equipe. Isto envolve os diversos tipos de robôs que estamos desenvolvendo na atualidade, como por exemplo, o ENITRON Master e o ENITRON Wolverine.",
      imagem1:
        "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-11-26+at+12.24.31+PM+(1).jpeg",
      imagem2: EnitronMaster2,
      link: "",
      intervalo: 4100,
    },

    {
      titulo: "Enitron Dance",
      descricao:
        "Robô recreativo de fácil programação com a capacidade de dançar e entreter.'Alma da festa'",
      imagem1: EnitronDance1,
      imagem2: EnitronDance2,
      link: "",
      intervalo: 4200,
    },

    {
      titulo: "Robô Futebol",
      descricao: "Consistem em robôs voltados para competições de robótica onde ocorrem jogos de futebol entre robôs.",
      imagem1: EnitronRoboFutebol1,
      imagem2: EnitronRoboFutebol2,
      link: "",
      intervalo: 4300,
    },

    {
      titulo: "Micromouse",
      descricao: "Um robô de competição voltado para resolver labirinto, utilizando sensores de movimentação e uma programação específica.",
      imagem1:
        "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-11-26+at+12.24.13+PM.jpeg",
      imagem2: EnitronMicromouse2,  
      link: "",
      intervalo: 4400,
    },
    /*
    {
      titulo: "Minitron",
      descricao: "Vesão reduzida do mascote principal.",
      imagem:
        "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
      link: "",
    },
    */
  ];

  const cadastraNewsletter = () => {
    CadastraEmail(emailNews)
      .then((r) => {
        if (r.status === 200) {
          console.log("RESULTADO API: ", r);
          toast.success("E-mail cadastrado com sucesso!");
        } else {
          toast.error(
            "Erro ao cadastrar, verifique seu e-mail e tente novamente!"
          );
        }
      })
      .catch((err) => {
        console.log("ERRO API: ", err);
        toast.error(err.response.data.msg);
      });
  };

  return (
    <>
      {/* ---------------------------- Modal Admin ------------------------------------*/}
      <Modal className="modal-dialog-centered" isOpen={modalLogin}>
        <ModalHeader>Login Admin</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>E-mail</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Insira seu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Input>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <Label>Senha</Label>
              <Input
                type="password"
                name="password"
                id="passsword"
                placeholder="Insira sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => submitLogin()}>Login</Button>{" "}
          <Button onClick={() => setModalLogin(false)}>Cancelar</Button>
        </ModalFooter>
      </Modal>

      {/* ---------------------------------- HEADER -------------------------------- */}

      <div id="page-top">
        <div className="header"></div>
        {/* Masthead*/}

        {/*
        <header
          style={{
            position: "relative",
            width: "100%",
            height: "110vh",
            overflow: "hidden",
            msOverflow: "hidden",
          }}
        >
          <img
            id="background-Image"
            style={{
              display: "block",
              position: "absolute",
              top: `${offSetY * 0.45}px`,
              left: "0",
              zIndex: "1",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
              userSelect: "none",
              msUserSelect: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
            }}
            src={backgroundTech}
            width="100%"
            height="100%"
            alt=""
          />
          <video
            id="background-Video"
            style={{
              position: "absolute",
              top: `${offSetY * 0.45}px`,
              left: "0",
              zIndex: "2",
              objectFit: "cover",
              pointerEvents: "none",
              userSelect: "none",
              msUserSelect: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
            }}
            width="100%"
            height="100%"
            autoplay="true"
            loop
            muted
            src="https://imagenssite.s3.amazonaws.com/backgroundV.mp4"
            type="video/mp4"
          ></video>

          <div
            id="background-Gradient"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "3",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              backgroundImage:
                "linear-gradient( transparent, rgba(0, 0, 255, 0.486), black)",
            }}
          >
            <img
              id="arm"
              style={{
                display: "block",
                position: "absolute",
                top: "55%",
                left: `${offSetY * 0.5}px`,
                zIndex: "4",
                transform: "translateY(-50%)",
                width: "100%",
                objectFit: "cover",
                pointerEvents: "none",
                userSelect: "none",
                msUserSelect: "none",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
              }}
              src={armCyborg}
              width="100%"
              alt="Braço de Cyborg"
            />
            <SlideTrack/>
          </div>
        </header>
        */}

        <SlideCarouselHome/>

        {/*  Patrocinadores  */}
        <section
          className="page-section"
          id="Patrocinadores"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "8rem 0rem",
            width: "100%",
          }}
        >
          <h2
            className="section-heading text-uppercase"
            style={{
              color: "rgb(27, 27, 27)",
              width: "100%",
              textAlign: "center",
            }}
          >
            Patrocinadores
          </h2>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <CarouselBasic />
          </div>
        </section>

        {/*---------------------------------------- Sobre nós ------------------------ */}
        {/*
        <section
          className="page-section"
          id="services"
          style={{
            backgroundImage: "linear-gradient(rgb(1, 18, 82), rgb(27, 27, 27))",
            backgroundSize: "cover",
            padding: "1.5rem 0rem",
          }}
        >
          <div className="container">
            <div className="text-center">
              <h2
                className="section-heading text-uppercase"
                style={{ color: "rgb(15, 131, 226)" }}
              >
                Sobre Nós
              </h2>

              <div
                className="row text-justify"
                style={{
                  color: "white",
                  textAlign: "justify",
                  height: openSobreNos ? "auto" : "100px",
                  overflow: "hidden",
                  transition: "all 1s ease-in-out",
                }}
              >
                &nbsp;&nbsp;&nbsp;A equipe Enitron Robótica traçou sua
                trajetória a partir de um projeto universitário que, quatro anos
                atrás, era coordenado por um professor comprometido e um grande
                amigo. À medida que o tempo avançava, o Engenheiro Anderson
                , conhecido como Prof. Anderson P. de Souza, assumiu a
                liderança desse projeto que foi dado por esse professor. Esse
                marco trouxe consigo a transição para um nome que refletisse a
                equipe e seus objetivos.
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;Prof. Anderson P. de Souza e os membros da equipe 
                enfrentaram o desafio de escolher um nome que transmitisse a essência da
                equipe, evitando qualquer possibilidade de interpretação
                negativa. O resultado dessa árdua tarefa foi a criação do nome
                "Enitron". Esse nome não é apenas um arranjo de letras, mas um
                símbolo da dedicação, colaboração e audaciosas aspirações da
                equipe.
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;A construção da equipe ocorreu por meio de uma
                criteriosa seleção de alunos do Centro Universitário Eniac. A
                abordagem meticulosa do Prof. Anderson P. de Souza para organizar a 
                equipe estabeleceu as bases para o que a Enitron se tornaria. Sob essa estrutura
                sólida, o nome "Enitron" surgiu, incorporando um significado
                profundo.
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;As iniciais do nome Enitron representam os
                pilares fundamentais que a equipe abraça: Engenharia,
                Integração, Tecnologia, Robótica e a busca pela excelência
                Nacional. O nome é uma fusão de letras que encapsula a dedicação
                de cada membro da equipe em todos esses campos.
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;A missão da Enitron Robótica é clara: conduzir
                pesquisas e desenvolvimento de robôs, tanto para competições
                desafiadoras quanto para avanços científicos. A equipe aspira a
                trilhar um caminho pioneiro no Brasil, visando à criação da
                primeira empresa de robótica do país.
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;Hoje, a equipe possui seu próprio símbolo,
                miniaturas dos robôs e projetos em andamento. No entanto, o
                desafio está longe de ser superado, pois a equipe continua sua
                pesquisa em busca do objetivo principal: construir um robô
                humanoide autônomo, com altura equivalente a uma criança de 10 a
                12 anos, ou seja, 1 metro e 20 centímetros.
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;A jornada da Enitron Robótica é uma narrativa
                de inovação, superação e colaboração. Cada membro da equipe é
                guiado pela visão compartilhada de criar algo extraordinário,
                rompendo as barreiras da tecnologia. A equipe se esforça para
                transformar seus sonhos em realidade, com o robô humanoide
                autônomo representando o ápice do comprometimento, trabalho
                árduo e espírito pioneiro que caracteriza a equipe Enitron.
              </div>
              <div>
                <button
                  style={{
                    marginTop: "1rem",
                    color: "white",
                    backgroundColor: "rgb(15, 131, 226)",
                    border: "none",
                    borderRadius: "5px",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    transition: "all 0.5s ease-in-out",
                  }}
                  className="btn btn-primary btn-xl text-uppercase"
                  id="btnLogin"
                  type="button"
                  onClick={() => setOpenSobreNos(!openSobreNos)}
                >
                  {openSobreNos ? "Fechar" : "Saiba Mais"}
                </button>
              </div>
            </div>
          </div>
        </section>
        */}
        
        {/* ------- Enitron ------- */}
        <section id="portfolio" className="page-section">
          <div className="text-center">
            <h2
              className="section-heading text-uppercase"
              style={{ color: "rgb(27, 27, 27)" }}
            >
              ENITRON
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              gap: "2rem",
              flexWrap: "wrap",
              marginTop: "2rem",
              marginBottom: "2rem",
              padding: "1rem",
            }}
          >
            {dadosRobo.map((dadosRobo, key) => (
              <CardRobo
                key={key}
                titulo={dadosRobo.titulo}
                descricao={dadosRobo.descricao}
                imagem1={dadosRobo.imagem1}
                imagem2={dadosRobo.imagem2}
                intervalo={dadosRobo.intervalo}
              />
            ))}
          </div>
        </section>

        {/* ------- Equipe Enitron ------- */}
        <section
          className="page-section"
          id="team"
          style={{
            backgroundImage: `url(${backgroundSobre})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundAttachment: "fixed",
            padding: "0rem",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: "linear-gradient(transparent, black)",
            }}
          >
            <div
              className="container"
              style={{ paddingTop: "4rem", paddingBottom: "8rem" }}
            >
              <CardEquipe />
            </div>
          </div>
        </section>

        {/*  Contact*/}
        <section className="page-section" id="contact">
          <div className="container">
            <div className="text-center">
              <h2 className="section-heading text-uppercase">
                Digite o seu e-mail para Receber Mais Notícias do Enitron!!!!
              </h2>
              <h3 className="section-subheading text-muted"></h3>
            </div>
            {/*  * * * * * * * * * * * * * * **/}
            {/*  * * SB Forms Contact Form * **/}
            {/*  * * * * * * * * * * * * * * **/}
            {/*  This form is pre-integrated with SB Forms.*/}
            {/*  To make this form functional, sign up at*/}
            {/*  https://startbootstrap.com/solution/contact-forms*/}
            {/*  to get an API token!*/}
            <form id="contactForm" data-sb-form-api-token="API_TOKEN">
              <div className="row align-items-center mb-5">
                <div className="col-md-4 offset-md-4  ">
                  <div className="form-group ">
                    {/*  Email address input*/}
                    <input
                      className="form-control text-center"
                      id="email"
                      type="email"
                      value={emailNews}
                      placeholder="Digite seu E-mail!!"
                      data-sb-validations="required,email"
                      onChange={(e) => setEmailNews(e.target.value)}
                    />
                    <div
                      className="invalid-feedback"
                      data-sb-feedback="email:required"
                    >
                      An email is required.
                    </div>
                    <div
                      className="invalid-feedback"
                      data-sb-feedback="email:email"
                    >
                      Email is not valid.
                    </div>
                  </div>
                </div>
              </div>

              {/*  Submit Button*/}
              <div className="text-center">
                <button
                  className="btn btn-primary btn-xl text-uppercase"
                  id="submitButton"
                  type="button"
                  onClick={() => cadastraNewsletter(email)}
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </section>

        {/*      
        <section className="page-section" id="galery">
          <GaleriaEquipe />
        </section>
        */}    
      </div>
    </>
  );
};

export default Home;
