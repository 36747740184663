/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Link,
  Route} from "react-router-dom";
import Home from "./pages/home";
import Header from "./components/header";
import About from "./pages/about";
import Cadastro from "./pages/cadastro";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardEquipe from "./components/CardEquipe";
import Admin from "./pages/admin/index";
import Dashboard from "./pages/dashboard";
import { Button, Modal } from "reactstrap";
import BtnWhatsapp from "./components/btnWhatsapp";
import Footer from "./components/footer";
import HeaderLogado from "./components/header";
import Usuarios from "./pages/admin/Usuarios"
import DashboardAdm from "./pages/admin/Dashboard";


const App = () => {

  const [modal, setModal] = useState(true);
  const [logado, setLogado] = useState(localStorage.getItem("IsLoged"));
  
  return (
    <>

      <ToastContainer/>

      <div style={{position: "absolute"}}>{logado === "true" ? <Header setLogado={setLogado} /> : <HeaderLogado setLogado={setLogado} />}</div>
      
      <BtnWhatsapp/>

      <div style={{position: "relative", top: "1.5rem"}}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Admin" element={<Admin />}> 
              <Route path="Usuarios" element={<Usuarios />} />
              <Route path="" element={<DashboardAdm />} />
          </Route>
          <Route path="/About" element={<About />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Cadastro" element={<Cadastro />} />
        </Routes>
      </div>

      <Footer/>
    </>
  ) 
}

export default App


  