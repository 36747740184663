/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { ListaUsuarioTeam } from "../../services/usuarios";

import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import { Row } from "reactstrap";
import './CardEquipeStyle.css';

const CardEquipe = () => {
  const [dadosEquipe, setDadosEquipe] = useState([]);
  console.log("dadosEquipe", dadosEquipe);

  const dadosBill = {
    _id: "1",
    nome: "Anderson Paulo de Souza",
    descricao: "Big Boss",
    especialidade: "Engenheiro Geral",
    avatar: "https://avatarusuarios.s3.sa-east-1.amazonaws.com/bill.jpeg",
    linkedin: "https://www.linkedin.com/in/anderson-paulo-de-souza-479331137/",
  };

  //const [dadosCard, setDadosCard] = useState([{}]);
  const [dadosCard, setDadosCard] = useState([
    {
			"_id": "64fca6584a67f0b178ad51ad",
			"nome": "ANDRE LUIZ TORRES",
			"email": "andre.torres.ti@gmail.com",
			"telefone": "41995298870",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/ANDRELUIZTORRES.jpeg",
			"linkedin": "https://www.linkedin.com/in/andr%C3%A9-luiz-torres-b367a062/",
			"github": "https://github.com/andreluiztorres",
			"cidade": "Pinhais",
			"estado": "PR",
			"pais": "BR",
			"password": "$2b$12$yaGVRc7/V7kmhk6CzHss2eqSO2U9t535KvJ9hd2ddaBTt2neDfd8y",
			"descricao": "... pelomenos a gente tenta !!",
			"especialidade": "RoboMaker intusiasta",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64fcad364a67f0b178ad51b5",
			"nome": "Rafaela Passos ",
			"email": "rafa_dio@hotmail.com",
			"telefone": "1196642502",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/RafaelaPassos.jpg",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$YO96kDpZvT86iuNc5jowbeeXwxBGiDYLBkugFybOgBSrhzY.gce8i",
			"descricao": "Faço parte da equipe ENITRON, sou atualmente líder da célula de robô de futebol e estou sempre aberta a novos conhecimentos.",
			"especialidade": "Elétrica e eletrônica ",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64fcae6f4a67f0b178ad51c5",
			"nome": "Willian Ribeiro da Fonseca",
			"email": "wrf.fonseca@gmail.com",
			"telefone": "11959817032",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/WillianRibeirodaFonseca.png",
			"linkedin": "https://www.linkedin.com/in/willian-ribeiro-fonseca-8b8b69204/",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$VeM28QaAwJKOiPncq9TYmu4E8QHreo1nKPOlldUJ7ojm3jJ/eVuCa",
			"descricao": "Pragmático",
			"especialidade": "Elétrica | Eletrônica | Cad | Desenho Técnico",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64fcdbd24a67f0b178ad51e0",
			"nome": "Arthur dos Santos Gomes",
			"email": "arthur.gms2002@gmail.com",
			"telefone": "(11) 96482-9822",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/ArthurdosSantosGomes.jpeg",
			"linkedin": "https://www.linkedin.com/in/arthur-dos-santos-gomes/",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$ElFqbRbu.maw4FgQvWEo6.Ix7f.fx1ZO.pDHQraK0BZGgfeIM0cy2",
			"descricao": "Gosto muito de estar aprendendo e sair da minha zona de conforto, como realizar algumas coisas que antes não teria coragem.",
			"especialidade": "Desenho, Operar máquinas, Estudar",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64fe16034a67f0b178ad5221",
			"nome": "Kelvin Gabriel",
			"email": "kelvingbl12@hotmail.com",
			"telefone": "11976291538",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/KelvinGabriel.png",
			"linkedin": "https://br.linkedin.com/in/kelvin-gabriel-759073126",
			"github": "https://github.com/KelvinGBL",
			"cidade": "São Paulo",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$744/VqeNOZnn7WO//G84SuJQKSMzoy5ri3ETlOFNX.h4pMSsfJXYK",
			"descricao": "Estudante",
			"especialidade": "Front End Developer",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64ff90014a67f0b178ad5247",
			"nome": "Victor Fernando Azevedo Monteiro",
			"email": "victorf.azevedo1504@gmail.com",
			"telefone": "(11) 99862-2525",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/VictorFernandoAzevedoMonteiro.jpeg",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$IOkj1JKtFISPPqreUy3U2Ob3JqR7qphYRYIoIyE4MqmO.ziY6b96q",
			"descricao": "Perspicaz e Dedicado",
			"especialidade": "Elétrica/Mecânica",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64ff90d94a67f0b178ad525b",
			"nome": "Willian Santos De Aguilar ",
			"email": "willianaguilar84@gmail.com",
			"telefone": "11 945234487",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/WillianSantosDeAguilar.jpeg",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$0WvoWMrlF6OURgIlu81eVeKZQ/4iwnhPekbpfr1FuSyAdF1GkwRnO",
			"descricao": "Entusiasta ",
			"especialidade": "Impressão 3D/Mecânica",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "64ff9f274a67f0b178ad5260",
			"nome": "Andressa Rodrigues",
			"email": "203462017@eniac.edu.br",
			"telefone": "11986112588",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/AndressaRodrigues.JPG",
			"linkedin": "",
			"github": "",
			"cidade": "guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$6PGG/fGNiRdAqax1XRCesO4YpQOIZpVPeDRPSMkbL.Ipj7W6BVwtK",
			"descricao": "Sou extrovertida, dinâmica, observadora e gosto sempre de estar aprendendo.",
			"especialidade": "Mecânica",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "6500e4bc4a67f0b178ad5272",
			"nome": "Gabriela Rodrigues",
			"email": "287712020@eniac.edu.br",
			"telefone": "11 941735438",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/GabrielaRodrigues.jpg",
			"linkedin": "https://www.linkedin.com/learning/me/my-library/in-progress",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$TaFYbB6U69eSvfxKYwejk.AuDoX3VnUAZI8srrTgipZdWxoDQOWoC",
			"descricao": "Adoro aprender coisas novas",
			"especialidade": "Eletroeletronica",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "650274214a67f0b178ad5296",
			"nome": "Vitor Sotero Grangeiro",
			"email": "vitorsotero131@gmail.com",
			"telefone": "988466493",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/VitorSoteroGrangeiro.jpg",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$A1GyyeoGadtDeBmN2/01IOMYlSK1qW/cL420VLme3plmYL.rAzDQi",
			"descricao": "Técnico em Mecatrônica, cursando Engenharia Mecatrônica, atuante na área de manutenção elétrica de máquinas e líder da célula do robô micromouse da equipe Enitron ",
			"especialidade": "Elétrica ",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "650b6a724a67f0b178ad54e3",
			"nome": "Daniel Silva Santos",
			"email": "256442021@eniac.edu.br",
			"telefone": "11912891271",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/DanielSilvaSantos.jpg",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$9C5.Afm0jzc7ejUi8SjxxujbLvNwGLG5OkqtG9VL1LgrRPjghE9lS",
			"descricao": "Eu reclamo mas resolvo",
			"especialidade": "Modelagem 3D",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "650b7e5f4a67f0b178ad54f5",
			"nome": "Felipe Beltran",
			"email": "felipe_beltran12@outlook.com",
			"telefone": "11980211289",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/FelipeBeltran.png",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$5sgWGIP0D90L6DFsOrmFGup1pZ4iWvBXPu/c4gANN/0MD7D9ITObK",
			"descricao": "Gosto de projetar, já atuo na área desde 2022. ",
			"especialidade": "Projetar",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		},
		{
			"_id": "653879564a67f0b178ad55b5",
			"nome": "Victor Cozeto",
			"email": "victorcozeto@gmail.com",
			"telefone": "11945219009",
			"avatar": "https://avatarusuarios.s3.sa-east-1.amazonaws.com/VictorCozeto.jpg",
			"linkedin": "",
			"github": "",
			"cidade": "Guarulhos",
			"estado": "SP",
			"pais": "BR",
			"password": "$2b$12$j7hNbS6vJlfN64IUL1V68ekLChs94ZzEKKtdTeseggkzj8n7..rg6",
			"descricao": "Aprendendo a Aprender",
			"especialidade": "Técnico de Infraestrutura e Desenvolvedor React",
			"type": "1",
			"forumpoints": 0,
			"__v": 0
		}
  ]);

  //console.log("dadosCard2", dadosCard2);

  const getListaDados = () => {
    
          var lista = dadosCard;
          lista.unshift(dadosBill)
          setDadosEquipe(lista);
          setDadosCard([
            {
              _id: lista[0]._id,
              nome: lista[0].nome,
              descricao: lista[0].descricao,
              especialidade: lista[0].especialidade,
              avatar: lista[0].avatar,
              linkedin: lista[0].linkedin,
            },
          ]);
        }
      

  useEffect(() => {
    getListaDados();
  }, []);

  return (
    <>
      <div className="text-center">
        <h2 className="section-heading text-uppercase" style={{color: "white"}}>Equipe Enitron </h2>
      </div>

        <Row>
        <div id="conteiner-equipe">
          <div>
            <div id="card-equipe">
                <div id="card-background">
                    <div id="card-image">
                      <img src={dadosCard[0].avatar} alt=""/>
                    </div>
                </div>
                <div 
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    height: "280px",
                    fontFamily: "Arial, Helvetica, sans-serif",
                  }}>
                    <div style={{padding: "1rem 1rem"}}>
                        <h3 
                          style={{
                            color: "rgb(1, 18, 82)",
                            lineHeight: "2rem",
                            fontSize: "1.4rem",
                            fontWeight: "500"
                          }}>
                          {dadosCard[0].nome}
                        </h3>
                        <h4
                          style={{
                            color: "gray",
                            lineHeight: "2.4rem",
                            fontWeight: "bold",
                            fontSize: "1.2rem"
                          }}>
                          {dadosCard[0].especialidade !== undefined
                                ? dadosCard[0].especialidade : ""}
                        </h4>
                        <p 
                          style={{
                            color: "gray",
                            lineHeight: "1.6rem"
                          }}>
                          {dadosCard[0].descricao}
                        </p>
                    </div>
                </div>
            </div>
            <div id="card-social">
              <a
                className="btn btn-dark btn-social mx-2"
                style={{backgroundColor: "transparent"}}
                href={dadosCard[0].linkedin}>
                  <FaLinkedinIn size={25} />
              </a>
              {/*
              <a
                className="btn btn-dark btn-social mx-2"
                style={{backgroundColor: "transparent"}}
                href="#">
                  <FaGithub size={25} />
              </a>
              */}
            </div>
          </div>
          <div>
            <div id="conteiner-equipe2">
              {dadosEquipe.map((item) => (
                <>
                  <div
                    key={item.id}
                    className="cardEquipeHome"
                    onClick={() => {
                      console.log("Clicked ", item);
                      setDadosCard([item]);
                    }}
                    style={{
                      backgroundImage: `url("${item.avatar}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      height: "90px", width: "90px"
                    }}>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </Row>

    </>
  );
};

export default CardEquipe;
