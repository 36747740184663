import React from 'react'
import dash from '../../../assets/images/WhatsApp Image 2023-07-08 at 11.39.37.jpeg'

const DashboardAdm = () => {
  return (
    <>
    <img src={dash} width="100%"></img>
    </>
  )
}

export default DashboardAdm