import { useState } from "react";
import {IoLogoWhatsapp} from "react-icons/io";
import React from "react";

const BtnWhatsapp = () => {
  const [isHover, setIsHover] = useState(false);

  const divStyle = {
    position: "fixed",
    right: "0",
    bottom: "0",
    zIndex: "999",
    margin: "2rem"
  };
  const aStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: "white",
    height: "80px",
    width: "80px",
    fontSize: "2.8rem",
    backgroundImage: isHover ? "linear-gradient(rgb(23, 144, 243), rgb(7, 19, 184))" : "linear-gradient(rgb(15, 131, 226), rgb(4, 14, 148))",
    border: "none",
    borderRadius: "20%",
    transition: "0.3s",
    transform: isHover ? "scale(1.1)" : "scale(1.0)"
  };
  return (
    <>
      <div style={divStyle}>
        <a style={aStyle}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          href= "https://api.whatsapp.com/send/?phone=554195298870&text=Olá%20Andre&type=phone_number&app_absent=0"
          target= "_blank" rel="noreferrer">
          <IoLogoWhatsapp title="Whatsapp" size= {60}/>
        </a>
      </div>
    </>
  );
};
  
export default BtnWhatsapp;