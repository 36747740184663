import React from "react";

//CSS
import './SlideTrack.css';
//Icons
import { BsFillTrophyFill } from "react-icons/bs";

const SlideTrack = ()=>{
    const listSlideTrack =[
        {titulo: "Battle Bot Torneio Eniac Em Breve"},
        {titulo: "Boxing Bot Torneio Eniac Em Breve"},
        {titulo: "Follow Line Bot Torneio Eniac 2024"},
        {titulo: "Maze Bot Torneio Eniac Em Breve"},
        {titulo: "Soccer Bot Torneio Eniac 2024"}
    ];

    const Slide = (props)=>{
        return(
            <>
                <div class="slideInfo">
                    <a href="#">
                        <BsFillTrophyFill />
                        {props.titulo}
                    </a>
                </div>
            </>
        );
    };

    return(
        <>
            <div class="slider">
                <div class="slide-track">
                    {
                        listSlideTrack.map((listSlideTrack, key) => (
                            <Slide
                                key={key}
                                titulo={listSlideTrack.titulo}/>
                        ))
                    }
                    {
                        listSlideTrack.map((listSlideTrack, key) => (
                            <Slide
                                key={key} 
                                titulo={listSlideTrack.titulo}/>
                        ))
                    }
                </div>
            </div>
        </>
    );
};
  
export default SlideTrack;