/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Container,
} from "reactstrap";

const CarouselVendas = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(0);

  const listCarousel = [
    [
      {
        id: "1",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      {
        id: "2",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      {
        id: "3",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
    ],
    [
      {
        id: "4",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      {
        id: "5",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      {
        id: "6",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
    ],
    [
      {
        id: "7",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      {
        id: "8",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
      {
        id: "9",
        Image:
          "https://imagenssite.s3.amazonaws.com/WhatsApp+Image+2022-10-22+at+10.43.39+AM.jpeg",
        Title: "Minitron Barateza",
        Price: 17.99,
        Link: "#",
        Description:
          "Lorem Ipsum is simply dummy text of the printing and typesettingindustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      },
    ],
  ];

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === listCarousel.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? listCarousel.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = listCarousel.map((item) => {
    var itensCards = item;
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited}>
        <div style={{ display: "flex", width: "100%", height: "700px" }}>
          {itensCards.map((images) => {
            return (
              <>
                <div
                  style={{
                    display: "grid",
                    marginLeft:"20px",
                    marginRight:"20px",
                    width: "30%",
                  }}
                >
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="12">
                          <h3> {images.Title} </h3>
                          <img src={images.Image} width="100%" maxWidth="300px"></img>
                          <p style={{textAlign:"justify"}}> {images.Description} </p>
                          <div style={{textAlign:"center"}}> Por Apenas </div>
                          <h2 style={{textAlign:"center"}} > R$ {images.Price} </h2> <br/>
                          <Button onClick={images.Link} className="btn btn-success" block > Clique Aqui para saber mais! </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </>
            );
          })}
        </div>
      </CarouselItem>
    );
  });
  return (
    <Container id="viewstl">
      <Carousel
        autoPlay={true}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={4000}
        slide={true}
      >
        <CarouselIndicators
          items={listCarousel}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </Container>
  );
};

export default CarouselVendas;
