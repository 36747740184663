/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";

//Images
import logoDanvit from "../../assets/images/logos/logos_patrocinadores/logoDanvit.jpg";
import logoDatassive from "../../assets/images/logos/logos_patrocinadores/logoDatassive.png";
import logoSetta from "../../assets/images/logos/logos_patrocinadores/logoSetta.jpg";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  Container,
} from "reactstrap";

const CarouselBasic = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(0);

  const listCarousel = [
    [
      {
        id: "2",
        Link: "https://www.solutiontech.eng.br/",
        Image: "https://logopatrocinadores.s3.amazonaws.com/2.jpg",
      },
      {
        id: "7",
        Link: "https://3dfila.com.br/",
        Image: "https://logopatrocinadores.s3.amazonaws.com/7.jpeg",
      },
      {
        id: "3",
        Link: "https://www.ondulapel.com.br/",
        Image: "https://logopatrocinadores.s3.amazonaws.com/3.jpg",
      },
    ],
    [
      {
        id: "5",
        Link: "https://curtocircuito.com.br/",
        Image: "https://logopatrocinadores.s3.amazonaws.com/5.jpg",
      },
      {
        id: "4",
        Link: "http://www.sgece.com.br/",
        Image: "https://logopatrocinadores.s3.amazonaws.com/4.jpg",
      },
      {
        id: "6",
        Link: "https://www.eniac.com.br/",
        Image: "https://logopatrocinadores.s3.amazonaws.com/1.jpg",
      },
    ],
    [
      {
        id: "7",
        Link: "#",
        Image: logoDanvit,
      },
      {
        id: "8",
        Link: "#",
        Image: logoDatassive,
      },
      {
        id: "9",
        Link: "#",
        Image: logoSetta,
      },
    ]
    // [      
    //   {
    //     id: "1",
    //     Link: "https://www.eniac.com.br/",
    //     Image: "",
    //   },{
    //     id: "1",
    //     Link: "https://www.eniac.com.br/",
    //     Image: "https://logopatrocinadores.s3.amazonaws.com/1.jpg",
    //   },{
    //     id: "1",
    //     Link: "https://www.eniac.com.br/",
    //     Image: "",
    //   },
    // ],
  ];

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === listCarousel.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? listCarousel.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = listCarousel.map((item) => {
    var itensCards = item
    return (
      <CarouselItem
        onExiting={onExiting}
        onExited={onExited}
      >
      {itensCards.map((images) => {
        return (
            <>
            <img style={{cursor: "pointer", height: "80px"}} onClick={() => window.open(images.Link)} src={images.Image}></img>{"  "}
            </>
        )
      })}  
      </CarouselItem>
    );
  });
  return (
    <Container id="viewstl">
      <Carousel
        autoPlay={true}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={4000}
        //slide={true}
        ride="carousel"        
      >
        {/* <CarouselIndicators
          items={listCarousel}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        /> */}
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </Container>
  );
};

export default CarouselBasic;
