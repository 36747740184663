import React, { useState } from 'react';

//Images
import BattleBotImage from "../../assets/images/BattleBot_Image.png";
import FollowLineBotImage from "../../assets/images/FollowLineBot_Image.png";
import SoccerBotImage from "../../assets/images/SoccerBot_Image.png";

//Component
import SlideTrack from '../SlideTrack';

//Component Reactstrap
import {
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap';

const SlideCarouselHome = ()=>{
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items=[
        {
          altText: 'Battle Bot Torneio Eniac',
          caption: 'Battle Bot Torneio Eniac',
          text: 'No coração pulsante da equipe de robótica Enitron, o Torneio de Battle Bot emerge como um espetáculo de engenhosidade e estratégia, onde máquinas habilmente projetadas colidem em uma dança de destreza e resistência. Além da competição, o torneio é uma oportunidade para os participantes e espectadores se conectarem com a comunidade de robótica.',
          key: 1,
          src: BattleBotImage
        },
        {
          altText: 'Follow Line Bot Torneio Eniac',
          caption: 'Follow Line Bot Torneio Eniac',
          text: 'O Follow Line Bot é um fascinante torneio realizado no âmbito do Eniac, proporcionando uma experiência empolgante e educativa para os participantes. Neste evento inovador, equipes desenvolvem e aprimoram robôs capazes de seguir uma linha traçada no chão, combinando habilidades de programação, engenharia e pensamento estratégico.',
          key: 2,
          src: FollowLineBotImage
        },
        {
          altText: 'Soccer Bot Torneio Eniac',
          caption: 'Soccer Bot Torneio Eniac',
          text: 'Os participantes enfrentam o desafio de projetar robôs que possuam não apenas a habilidade de se movimentar pelo campo, mas também de interagir com a bola e realizar ações fundamentais do futebol, como passes, chutes a gol e defesas. Isso implica na combinação de algoritmos sofisticados, sensores precisos e técnicas de controle eficientes.',
          key: 3,
          src: SoccerBotImage
        }
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    
    const slides = items.map((item) => {
        return (
            <CarouselItem
                className="custom-tag"
                tag="div"
                key={item.src}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <img
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                    src={item.src} 
                    alt={item.altText} />
                <div 
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 50%, #000 100%)",
                        width: "100%",
                        height: "100%",
                    }}>
                </div>
                <div 
                    style={{
                        position: "absolute",
                        right: "15%",
                        top: "4rem",
                        left: "15%",
                        paddingTop: "1.25rem",
                        paddingBottom: "1.25rem",
                        color: "#fff",
                        textAlign: "left",
                        lineHeight: "2rem",
                    }}>
                        <h2 style={{fontFamily: "Arial, sans-serif", marginBottom: "1rem"}}>{item.caption}</h2>
                        <p style={{lineHeight: "2rem", fontSize: "1rem"}}>{item.text}</p>
                </div>
            </CarouselItem>
        );
    });

    return(
        <>
            <div style={{position: "relative"}}>
                <style>
                    {`
                    .custom-tag {
                        position: relative;
                        max-width: 100%;
                        height: 540px;
                        background: black;
                    }
                    @media screen and (max-width: 500px) {
                        .custom-tag{
                            height: 95vh;
                        }
                    }
                    @media screen and (max-width: 350px) {
                        .custom-tag{
                            height: 650px;
                        }
                    }
                    `}
                </style>
                <Carousel
                    activeIndex={activeIndex} 
                    next={next} 
                    previous={previous}>
                    {slides}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                    />
                </Carousel>
                <SlideTrack/>
            </div>
        </>
    );
};
  
export default SlideCarouselHome;