import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";

import { BsList } from "react-icons/bs";
import logoPNG from "../../assets/images/LogoEnitronPNG.png";

import "react-toastify/dist/ReactToastify.min.css";
import api from "../../services/api";
import { toast } from "react-toastify";
import "./headerStyle.css";

const HeaderLogado = () => {
  const [modalLogin, setModalLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoged, setIsLogged] = useState(localStorage.getItem("IsLoged"));
  console.log("LOGADO: ", isLoged);
  const history = useNavigate();

  const logado = () => {
    history("/Dashboard");
  };

  const submitLogin = () => {
    localStorage.setItem("IsLoged", true);
    setIsLogged(true);

    const body = {
      email: email,
      password: password,
    };

    api
      .post("auth/login/", body)
      .then((r) => {
        console.log("RESULTADO API: ", r);
        localStorage.setItem("token", r.data.token);
        setModalLogin(false);
        toast.success("Login realizado com sucesso!");
        setIsLogged(true);
        logado();
      })
      .catch((err) => toast.error(err.response.data.msg));
  };

  const irHome = () => {
    localStorage.removeItem("IsLoged");
    localStorage.removeItem("token");
    setIsLogged(false);
    history("/");
  };

  const irPara = () => {
    history("/Cadastro");
    setModalLogin(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("IsLoged");
    setIsLogged(false);
    history("/");
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={modalLogin}>
        <ModalHeader>Login</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>E-mail</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Insira seu e-mail cadastrado"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Input>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <Label>Senha</Label>
              <Input
                type="password"
                name="password"
                id="passsword"
                placeholder="Insira sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Input>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div style={{ cursor: "pointer" }} onClick={() => irPara()}>
                Ainda não tem cadastro.
                <strong> Clique aqui</strong>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => submitLogin()}>Login</Button>{" "}
          <Button onClick={() => setModalLogin(false)}>Cancelar</Button>
        </ModalFooter>
      </Modal>

      <nav
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "68px",
          backgroundColor: "transparent",
          borderBottom: "1px solid white",
        }}
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "1200px",
            padding: "0rem 1rem",
          }}
          className="container_navbar"
        >
          <a
            className="btn-logo"
            title="Logo Enitron"
            href="#page-top"
            onClick={() => irHome()}
          >
            <img id="logo" src={logoPNG} alt="Logo Enitron" />

          </a>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Sobre Nós
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#portfolio">
                  Enitron
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team">
                  O Time
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://forum.roboenitron.com.br/" target="_blank" rel="noreferrer">
                  Fórum
                </a>
              </li>
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginLeft: "30px",
            }}
          >
            {/* Login */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.8rem",
                marginRight: "1.2rem",
              }}
            >
              <button className="btn-login" onClick={() => setModalLogin(true)}>
                Fazer Login
              </button>
              <button className="btn-menu">
                <BsList title="Menu" />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderLogado;
