import api from "./api";

export const CadastroUsuario = async (body) => {
  return api
    .post("user/save", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const BuscaUsuarioPorId = async (body) => {
  return api
    .post("user/save", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => console.log(response))
    .catch((err) => {
      console.log("Erro ao criar Usuário", err);
    });
};

export const ListaUsuarioTeam = async () => {
  return api
    .get("/public/equipe", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => 
      response.data.user
    )
    .catch((err) => {
      console.log("Erro ao criar Usuário", err);
    });
};
