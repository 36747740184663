/* eslint-disable no-unused-vars */
import "react-toastify/dist/ReactToastify.min.css";
import roboprojeto from "../../../src/assets/images/Robo1.jpg";
import roboconsultoria from "../../../src/assets/images/robo-advisor-consultoria-robo.png";
import CarouselVendas from "../../components/CarouselVendas/index";
import {
  Button,
  Row,
  Col,
  Card,
  CardTitle,
  CardHeader,
  CardText,
  CardLink,
  CardBody,
} from "reactstrap";

/* Constantes para que haja a coleta de dados do cadastro! */

const Dashboard = () => {
  /* Após declarada as funções, renderizar no site  */
  return (
    <>
      <h1>Dashboard</h1>

      <br></br>

      {/*------------------------- Parte inicial descrevendo o Fórum e suas perguntas Frequentes ---------------------------------------------------------------- */}
      {/* 
      <Row>
        <Col sm="12" lg="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Fórum</CardTitle>
            </CardHeader>
            <CardBody>
              {" "}
              <CardText>
                Destinado para você, aluno ou ensusiasta da robótica que quer se
                tornar um Tony Stark e precisa de ajuda ou tirar algumas
                dúvidas. Entre no nosso fórum.
              </CardText>
              <Col sm="12" lg="6">
                <Button>Vem pro Fórum</Button>
              </Col>
            </CardBody>
          </Card>
        </Col>

        
        <Col sm="12" lg="6">
          <Card outline size="sm">
            <CardHeader tag="h5">Perguntas Frequentes do Fórum</CardHeader>
            <CardBody>
              <CardTitle tag="h6">
                Qual o melhor componente para se usar nos robôs de sumô ?
              </CardTitle>
              <CardText>Capacitores de alta tensão, dentre outros.</CardText>
              <CardTitle tag="h6">Como medir a tensão ?</CardTitle>
              <CardText>Utilizando um Multímetro.</CardText>
              <CardLink href="#">Clique Aqui para ver mais perguntas</CardLink>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br></br>
      */}

      {/*--------------------------------------- Área Calendário-------------------------------------------------------- */}
      <Row>
        <Card>
        <CardHeader tag="h5">Calendário</CardHeader>
          <CardBody>
          


       
      {/* ---------------------------------------------------Área que descreverá cursos da semana, eventos e torneios---------------------------------------------- */}
      <Row>
        <Col sm="12" lg="4">
          <Card>
            <CardHeader tag="h5">Aulas de robótica </CardHeader>

            <CardBody>
              <Card>
                <CardHeader tag="h6">Arduino</CardHeader>
                <CardBody>
                  <CardText>
                    Aprenda os princípios fundamentais de Arduino.
                    <CardLink href="#"> Inscreva-se</CardLink>
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardHeader tag="h6">Eletrônica</CardHeader>
                <CardBody>
                  <CardText>
                    Aprenda os princípios fundamentais de Eletrônica.
                    <CardLink href="#"> Inscreva-se</CardLink>
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardHeader tag="h6">C </CardHeader>
                <CardBody>
                  <CardText>
                    Aprenda os princípios fundamentais de C. <br></br>
                    <CardLink href="#"> Inscreva-se</CardLink>
                  </CardText>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>

        {/* ---------------------------------------------------------------- Torneios ---------------------------------------------------------------- */}
        <Col sm="12" lg="4">
          <Card>
            <CardHeader tag="h5">Torneios de Robotica</CardHeader>
            <CardBody>
              <CardText>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </CardText>
            </CardBody>
          </Card>
        </Col>

        {/* --------------------------------------------- Eventos ---------------------------------------------------------------------------------------- */}
        <Col sm="12" lg="4">
          <Card>
            <CardHeader tag="h5">Feiras/Eventos</CardHeader>
            <CardBody>
              <CardText>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>

      </CardBody>
        </Card>
      
      </Row>
      <br></br>

      {/* ------------------------------------------------------- Link de vendas ------------------------------------------------------------------- */}

      <Card>
        <CardHeader> Lojinha do Enitron </CardHeader>
        <CardBody>
          <CarouselVendas></CarouselVendas>
        </CardBody>
      </Card>
      <br></br>

      {/* --------------------------------------------------- Área de Patrocínio/ Doações ----------------------------------------------------------------------- */}
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader tag="h5">Doação - Novo projeto robo</CardHeader>
            <CardBody>
              <Row>
                <Col sm="12" lg="6">
                  <CardText>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                    <br></br>
                    <br></br>
                    <Button>Doar</Button>
                  </CardText>
                </Col>
                <Col sm="12" lg="6">
                  <CardText>R$15,000,000</CardText>
                  <img src={roboprojeto} width="500px" height="300px" alt="Robo"></img>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br></br>

      {/* ---------------------------------------------------- Área de solicitação de consultoria--------------------------------------------------------------- */}
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader tag="h5">Consultoria</CardHeader>
            <CardBody>
              <Row>
                <Col sm="12" lg="7">
                  <CardText>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </CardText>
                </Col>

                <Col sm="12" lg="5">
                  <img src={roboconsultoria} width="500px" height="300px" alt="Robo"></img>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
