/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./sidebarAdmin.css";
import { Link } from "react-router-dom";

const sidebarAdmin = () => {
  const [selected, setSelected] = useState(0);
  const botoes = [
    {
      nome: "Início",
      link: "",
    },
       {
      nome: "Usuário",
      link: "Usuarios",
    },
    {
      nome: "Produtos",
      link: "produtos",
    },
    {
      nome: "Eventos",
      link: "eventos",
    },
    {
      nome: "Loja",
      link: "loja",
    },
    {
      nome: "Donate",
      link: "donate",
    },
  ];

  return (
    <>
      <div className="sidebar">
        <ul>
          {botoes.map((bt) => {
            return (
              <>
                <li className="sidebar">
                  <Link className="sidebarbt" to={bt.link}>
                    <div className="backgroundSidebar">{bt.nome}</div>
                  </Link>
                </li>
                <hr></hr>
              </>
            );
          })}

        </ul>
      </div>
    </>
  );
};

export default sidebarAdmin;
