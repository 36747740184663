import React from "react";
import { useState } from "react";
import './cardRoboStyle.css';

import {
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap';

import { BsFillCaretDownFill } from "react-icons/bs";

const CardRobo = (props) => {
    const items = [
        {
            src: props.imagem1,
            altText: 'Slide 1',
            key: 1,
        },
        {
            src: props.imagem2,
            altText: 'Slide 2',
            key: 2,
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const slides = items.map((item) => {
        return (
          <CarouselItem style={{height: "100%"}}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            <img 
                style={{
                    height: "400px",
                    width: "300px",
                    objectFit: "cover"
                }} 
                src={item.src}
                alt={item.altText}
            />
          </CarouselItem>
        );
      });

    const [isHover, setIsHover] = useState(false);
    const [isClick, setIsClick] = useState(false);
    
    const btnClick = () => {
        if(isClick === true){
            setIsClick(false);
        }else{
            setIsClick(true);
        }
    };
    
    const cardStyle = {
        position: "relative",
        height: "400px",
        width: "300px",
        boxShadow: "2px 2px 1px rgba(0, 0, 0, 0.5)",
        overflow: "hidden",
        borderRadius: "10px",
        transition: "0.4s",
    };
    const cardBackgroundStyle = {
        position: "absolute",
        bottom: "0",
        display: "flex",
        flexDirection: "column",
        gap: "0.4rem",
        height: isClick ? "100%" : "120px",
        width: "100%",
        backgroundImage: "linear-gradient(transparent, blue)",
        padding: "0.8rem 1rem",
        transition: "0.4s",
    };
    const cardTextStyle = {
        display: isClick ? "block" : "none",
        opacity: isClick ?  "1" : "0",
        textAlign: "left",
        color: "white",
        fontSize: "1rem",
        lineHeight: "1.6rem",
        transition: "0.4s",
    };
    const cardBtnStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.2rem",
        backgroundColor: isHover ? "white" : "transparent",
        boxShadow: "2px 2px 1px rgba(0, 0, 0, 0.5)",
        marginRight: "2.2rem",
        borderTop: "2px solid white",
        borderLeft: "2px solid white",
        borderRight: "2px solid transparent",
        borderBottom: "2px solid transparent",
        borderRadius: "10px",
        padding: "0.6rem 0.6rem",
        color: isHover ? "blue" : "white",
        width: "100%",
        fontSize: "1rem",
        fontWeight: "600",
        textTransform: "uppercase",
        transition: "0.4s",
        opacity: isClick ? "0" : "1",
    };

    return (
      <>
        <div style={ cardStyle }>
            <Carousel 
                style={{height: "400px", width:"300px", background: "white"}}
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride='carousel'
                pause="false"
                interval={props.intervalo}
            >
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
            <div style={cardBackgroundStyle}>
                <div id="card-text">
                    <h3 id="card-title">
                        {props.titulo}
                    </h3>
                    <p style={cardTextStyle}>
                        {props.descricao}
                    </p>
                </div>
                <button 
                    id="card-button"
                    style={ cardBtnStyle }
                    onClick={() => btnClick()}
                    onBlur={() => setIsClick(false)}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}>
                    Saiba mais
                    <span style={{transform: "rotateZ(180deg)"}}>
                        <BsFillCaretDownFill size={25}/>
                    </span>
                </button>
            </div>
        </div>
      </>
    );
  };
  
  export default CardRobo;